import type { IView } from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import catalogSlider from '../inits/catalog-slider';
import indexSequence from '../inits/index-sequence';
import inCardSlider from '../inits/in-card-slider';

const beforeEnterQueue = new IdleQueue();

export default {
    namespace: 'index-page',

    beforeEnter({ next }) {
        beforeEnterQueue.pushTask(() => {
            indexSequence.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            catalogSlider.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            inCardSlider.init(next.container);
        });
    },

    afterEnter(data) {
        //
    },

    beforeLeave({ current }) {
        beforeEnterQueue.clearPendingTasks();
        catalogSlider.destroy();
        indexSequence.destroy();
        inCardSlider.destroy(current.container);
    },

    afterLeave({ current }) {
        //
    },
} as IView;
